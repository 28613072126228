<template>
  <div class="kt-spinner kt-spinner--sm kt-spinner--brand kt-spinner--right kt-spinner--input">
    <select
      @change="changedSelectPicker"
      class="form-control"
      v-bind:id="idSelectPicker"
      v-model="selected"
      :value="selectedValue"
      data-size="5"
      data-live-search="true"
      :disabled="disabledSelect"
    >
      <option
        v-for="item in dataResponse"
        :value="item.id"
        :key="item.id"
      >{{ item.id }} - {{ fullName(item) }}</option>
    </select>
  </div>
</template>

<script>
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const UserRepository = RepositoryFactory.get("user");

export default {
  data() {
    return {
      dataResponse: null,
      selected: null,
      disabledSelect: true
    };
  },
  props: {
    idSelectPicker: { type: String },
    selectedPicker: { type: Number }
  },
  computed: {
    selectedValue() {
      this.selected = this.selectedPicker;
    }
  },
  methods: {
    getDataById: function(id){
        return this.dataResponse.find(x => x.id == id);
    },
    fullName: function(val){
        const firstName = (val.firstName) ? val.firstName : '';
        const lastName = (val.lastName) ? val.lastName : '';
        return firstName+' '+lastName;
    },
    getSelectPicker: function() {
      this.getData();
    },
    changedSelectPicker: function() {
      this.$emit("update:changedValue", this.selected);
    },
    async getData() {
        var vx = this;
        // await UserRepository.get()
        new Promise((resolve, reject) => {
            UserRepository.get({}, resolve, reject );
        })
        .then(response => {
            vx.disabledSelect = false;
            vx.dataResponse = response.data;
            $("#" + vx.idSelectPicker).selectpicker();
            vx.$nextTick(() => {
            $("#" + vx.idSelectPicker).selectpicker("refresh");
            });
        }).catch(error => {
            var msg = (error.message) ? error.message : error;
            this.showToastError('User : ' + msg);
        });
    },
    showToastError: function(message) {
        toastr.error(message);
    }
  },
  mounted() {
    this.getSelectPicker();
  }
};
</script>


