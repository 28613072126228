<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-large modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{
                            this.kategoriPromo.id
                                ? (this.modeView ? "" : $t("button.edit"))
                                : $t("button.add")
                        }} {{ $t("module.promoCat") }}</h5>
                </div>
                <form @submit.prevent="submitData()">
                    <div class="modal-body">
                        <input
                            type="hidden"
                            class="form-control"
                            v-model.number="kategoriPromo.id"
                        />
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label"
                                >{{ $t("common.name") }}*:</label
                            >
                            <div class="col-lg-6">
                                <input
                                    type="text"
                                    :disabled="modeView==true"
                                    class="form-control"
                                    v-model="kategoriPromo.name"
                                    :placeholder="$t('common.promoCatName')"
                                    v-validate="{required:true,max:100 }"
                                    :data-vv-as="$t('common.promoCatName')"
                                    name="nama"
                                    :class="{'is-invalid': errors.has('nama')}"
                                />
                                <div v-show="errors.first('nama')" class="invalid-feedback">
                                    {{ errors.first("nama") }}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label">{{ $t("common.gambar") }}* :</label>
                            <div class="col-lg-6" v-if="!modeView">
                                <div class="dropzone" id="kt-dropzone-one" >
                                    <div class="dropzone-msg dz-message needsclick" >
                                        <img v-if="!modeView && dataEdit && !imgSelected"
                                            v-bind:src="getPathImage" style="width:100%" />
                                        <h1>
                                            <i class="fa fa-upload"
                                                aria-hidden="true"
                                            ></i>
                                        </h1>
                                        <h3 class="dropzone-msg-title">
                                            Upload
                                        </h3>
                                        <span class="dropzone-msg-desc">
                                            {{$t("sentences.drag_image_here_or_click_to_")}}
                                        </span>
                                        <div v-show="errors.first('selected_image')" class="custom-invalid-feedback">
                                            {{errors.first("selected_image")}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12" v-if="modeView">
                                <img v-bind:src="getPathImage" style="width:100%" />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-clean btn-bold btn-upper btn-font-md"
                            @click="hideModal()"
                        >
                            {{ $t("button.close") }}
                        </button>
                        <button
                            v-if="!modeView"
                            type="submit"
                            class="btn btn-default btn-bold btn-upper btn-font-md"
                        >
                            {{ $t("button.save") }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Cropper from "cropperjs";
import SelectPicker from "./../../../components/_general/SelectPicker";
import SweetAlertTemplate from "./../../../components/_general/SweetAlert";
import GenderOption from "./../../../components/_general/GenderOption";
import Photo from "./../../model/photo-model";
import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";
import { Validator } from "vee-validate";
import VeeValidate from "vee-validate";
import { isNullOrUndefined } from "util";
import KategoriPromo from "./../../model/kategori-promo-model";
import { mapState, mapMutations, mapActions } from "vuex";

import BlockUI from "./../../../components/_js/BlockUI";
const blockUI = new BlockUI();
const KategoriPromoRepository = RepositoryFactory.get("kategoriPromo");
const GalleryRepository = RepositoryFactory.get("galleryRepository");
const SweetAlert = Vue.extend(SweetAlertTemplate);

VeeValidate.setMode("passive");
export default {
    components: { SelectPicker },
    props: {
        event: {
            type: Function
        }
    },
    data() {
        return {
            kategoriPromo: new KategoriPromo(),
            photo: new Photo(),
            listPhoto : [],
            imgSelected:false,
            isMaxfilesExceeded: false,
            urlUpload: process.env.VUE_APP_API_URL + "/gallery/api/photos",
            urlImage:  process.env.VUE_APP_API_URL + "/gallery/api/image/alt"
        };
    },
    provide() {
        return { $validator: this.$validator };
    },
    computed: {
        ...mapState("Crud", {
            modalName: state => state.modalName,
            dataEdit: state => state.dataEdit,
            showModalMode: state => state.showModalMode
        }),
        getPathImage(){
            let result = this.dataEdit.image_path;
            if(this.dataEdit.image_path.indexOf(process.env.VUE_APP_API_URL)!==0){
                let slash = this.dataEdit.image_path.indexOf('/')===0? '':'/';
                result = this.dataEdit.image_path.indexOf('http')
                    === 0 ? this.dataEdit.image_path : this.urlImage + slash + this.dataEdit.image_path;
            }
            return result;
        },
        modeView(){
            return this.showModalMode == 'V';
        }
    },
    methods: {
        ...mapActions({
            createNew: "Crud/createNew",
            updateById: "Crud/updateById"
        }),
        ...mapMutations({
            clearData: "Crud/CLEAR_DATA",
            refreshData: "Crud/REFRESH_TABLE",
            setEditData: "Crud/SET_EDIT_DATA",
            hideModal: "Crud/HIDE_MODAL",
        }),
        getServerError(error, defaultMsg) {
            if (_.isUndefined(error.response.data.params)) {
                return defaultMsg;
            }
            let serverError = error.response.data.params;
            let errorMsg = _.isUndefined(
                serverError[Object.keys(serverError)[0]][0]
            )
                ? defaultMsg
                : serverError[Object.keys(serverError)[0]][0];
            return errorMsg;
        },
        updateData: function() {
            if (this.dataEdit) {
                this.kategoriPromo = Object.assign({}, this.dataEdit);
            }
        },
        cropImage(myDropzone, file, done){
            var vx = this;
            if(vx.isMaxfilesExceeded){
                this.removeFile(file);
                let sa = new SweetAlert().showInfo({
                    swalTitle: "Max files",
                    swalText: "Max files Exceeded.",
                    swalType: "warning"
                });
                return;
            }

            // ignore files which were already cropped and re-rendered
            // to prevent infinite loop
            if (file.cropped) {
                return;
            } else {
                var editor = document.createElement("div");
                editor.style.position = "fixed";
                editor.style.left = 0;
                editor.style.right = 0;
                editor.style.top = 0;
                editor.style.bottom = 0;
                editor.style.zIndex = 9999;
                editor.style.backgroundColor = "#000";
                document.body.appendChild(editor);

                // Create confirm button at the top left of the viewport
                var buttonConfirm = document.createElement(
                    "button"
                );
                buttonConfirm.style.position = "absolute";
                buttonConfirm.style.right = "10px";
                buttonConfirm.style.top = "10px";
                buttonConfirm.setAttribute(
                    "class",
                    "btn btn-primary"
                );
                buttonConfirm.style.zIndex = 9999;
                buttonConfirm.textContent = vx.$i18n.t(
                    "button.crop"
                );
                editor.appendChild(buttonConfirm);
                buttonConfirm.addEventListener("click", function() {
                    // Get the canvas with image data from Cropper.js
                    var canvas = cropper.getCroppedCanvas({
                        width: 256,
                        height: 256
                    });

                    // Turn the canvas into a Blob (file object without a name)
                    canvas.toBlob(function(blob) {
                        myDropzone.createThumbnail(
                            blob,
                            myDropzone.options.thumbnailWidth,
                            myDropzone.options.thumbnailHeight,
                            myDropzone.options.thumbnailMethod,
                            false,
                            function(dataURL) {
                                file.cropped = true;
                                file.dataURL = dataURL;
                                myDropzone.emit(
                                    "thumbnail",
                                    file,
                                    dataURL
                                );

                                // Return the file to Dropzone
                                if(typeof done == 'function'){
                                    new Promise((resolve, reject) => {
                                        const reader = new FileReader();
                                        reader.readAsDataURL(blob);
                                        reader.onload = function(event) {
                                            let photo = new Photo(); // bikin object payload image untuk di post ke API
                                            var image = new Image(); // bikin object image kosong
                                            image.src = event.target.result; // isi object image kosong dari file yang diupload
                                            image.onload = function(i) { // saat load image masukan data image ke object payload image
                                                // console.log('createThumbnail3',vx.listPhoto);
                                                photo.image = event.target.result.substring(
                                                    event.target.result.indexOf(",") + 1
                                                ); // base64 image
                                                photo.title = file.name; // nama file
                                                photo.description = file.name; // deskripsi biasanya pake nama juga
                                                photo.imageContentType = file.type; // tipe file
                                                photo.height = this.height; // tinggi foto
                                                photo.width = this.width; // lebar foto
                                                photo.taken = moment(file.lastModified); // waktu pengambilan
                                                photo.uploaded = moment(); // waktu upload
                                                photo.uuid = file.upload.uuid; // uuid untuk membedakan proses file upload
                                                resolve(photo);
                                            };
                                        };
                                    }).then(photo => {
                                        vx.listPhoto.push(photo); // masukan foto kedalam listphoto
                                        done(blob); // setelah proses masuk list photo selesai maka lanjutkan proses on sending
                                    });
                                }
                            }
                        );
                    });
                    // Remove the editor from the view
                    document.body.removeChild(editor);
                });

                var buttonCancel = document.createElement(
                    "button"
                );
                buttonCancel.style.position = "absolute";
                buttonCancel.style.left = "10px";
                buttonCancel.style.top = "10px";
                buttonCancel.setAttribute("class","btn btn-danger");
                buttonCancel.style.zIndex = 9999;
                buttonCancel.textContent = "Cancel";
                editor.appendChild(buttonCancel);
                buttonCancel.addEventListener("click", function() {
                    myDropzone.removeFile(file);
                    document.body.removeChild(editor);
                });

                // Create an image node for Cropper.js
                var image = new Image();
                image.src = URL.createObjectURL(file);
                editor.appendChild(image);

                // Create Cropper.js
                var cropper = new Cropper(image, {
                    aspectRatio: 1/1
                });
            }
        },
        dropZone() {
            var vx = this;
            if(!this.modeView){
                let dropzoneConfig = {
                    paramName: "file", // The name that will be used to transfer the file
                    maxFiles: 1,
                    maxFilesize: 2, // MB
                    addRemoveLinks: true,
                    acceptedFiles: "image/jpeg,image/png,image/jpg,image/gi",
                    resizeWidth: true,
                    resizeQuality: 0.7,
                    autoProcessQueue: true,
                    createImageThumbnails: true,
                    url: this.urlUpload,
                    headers: {  // masukan header yang dibutukan bersama token
                        Accept: "application/json, text/plain, */*",
                        Authorization: "Bearer " + localStorage.tokens,
                        "cache-control": "no-cache",
                        "Content-Type": "application/json;charset=UTF-8"
                    },
                    transformFile: function(file, done) {
                        vx.cropImage(this,file,done);
                    },
                    init: function() {
                        //proses pengiriman/post  gambar ke API disini kita overwrite plugin pengiriman nya pake json bukan formdata
                        // onsending dilakukan perfile berdasarkan total file yang kita pilih pas upload
                        this.on("sending", function(file, xhr, formData) {
                            // console.log("sending", vx.listPhoto);
                            // cari object photo untuk di post berdasarkan proses file yang dilakukan
                            let photo = vx.listPhoto.find(x => x.uuid == file.upload.uuid);
                            // kirim object photo
                            const x = JSON.stringify(photo);
                            xhr.send(x);
                        });
                        // kalau success upload nya maka anda akan mendapatkan response alias untuk disimpan
                        // success masuk per proses upload
                        this.on("success", function(file, response) {
                            // klau disini responsenya dibuat sebuat object lalu di push ke object utama
                            vx.kategoriPromo.image_path = response.alias;
                            // jangan lupa hapus object photo dari list photo berdasarkan uuid proses
                            const i = vx.listPhoto.findIndex(x => x.uuid == file.upload.uuid);
                            if (i > -1) {
                                vx.listPhoto.splice(i, 1);
                            }
                            // load image/thumbnail
                            file.previewElement.querySelector("img").src = vx.urlImage + '/' + response.alias;
                        });
                        this.on("error", function(file, message) {
                            vx.kategoriPromo.image_path = null;
                            //kalau upload gagal tambilkan message error nya dan jangan lupa hapus proses di listphoto
                            const i = vx.listPhoto.findIndex(x => x.uuid == file.upload.uuid);
                            if (i > -1) {
                                vx.listPhoto.splice(i, 1);
                            }
                            $(file.previewElement)
                                .addClass("dz-error")
                                .find(".dz-error-message")
                                .text(
                                    "Upload Gagal, Silahkan hapus gambar dan coba upload kembali"
                                );
                        });

                        this.on("removedfile", function(file) {
                            if(vx.isMaxfilesExceeded) return;
                            vx.kategoriPromo.image_path = null;
                            vx.imgSelected = true;
                        });
                    }
                }; //config
                var myDropzone = new Dropzone("#kt-dropzone-one", dropzoneConfig);
                myDropzone.on("addedfile", file => {
                    vx.isMaxfilesExceeded = false;
                    const fileName = file.name;
                });
                myDropzone.on("maxfilesexceeded", file => {
                    vx.isMaxfilesExceeded = true;
                });
            }

        }, //dropzone()
        submitData: function() {
            this.errors.clear();
            if(this.kategoriPromo.image_path == null){
                this.errors.add({
                    field: "selected_image",
                    msg: 'Silahkan pilih Gambar', // this.$t("sentences.invalid_attachments_new"),
                    rule: "required"
                });
            }
            this.$validator.validateAll().then(result => {
                if (result && this.kategoriPromo.image_path != null) {
                    var vx = this;
                    if (this.kategoriPromo.id) {
                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Perubahan data akan tersimpan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                const data = {};
                                _.forEach(vx.kategoriPromo, (v, k) => {
                                    if(v != null){
                                        data[k] = v;
                                    }
                                });
                                vx.updateKategori(data, vx.kategoriPromo.id);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    } else {
                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Data akan ditambahkan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                const data = {};
                                _.forEach(vx.kategoriPromo, (v, k) => {
                                    if(v != null){
                                        data[k] = v;
                                    }
                                });
                                vx.createKategori(data);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    }
                    return;
                }
            });
        }, //submitdata
        async createKategori(payload) {
            var vx = this;
            await KategoriPromoRepository.createKategori(payload)
                .then(response => {
                    new Promise((resolve, reject) => {
                            vx.$emit("event", resolve);
                            resolve(response);
                        }).
                        then((response) => {
                            let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: "Data Kategori berhasil ditambahkan.",
                            swalType: "success",
                            onConfirmButton: function () {
                                vx.refreshData(true);
                                blockUI.unblockModal();
                                vx.hideModal();
                                }
                            });
                        }).catch(error=>{

                        });
                    })
                    .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: vx.getServerError(
                            error,
                            "Data Kategori gagal ditambahkan."
                        ),
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                    });
            },
            async updateKategori(payload,categoryId) {
                var vx = this;

                await KategoriPromoRepository.updateKategori(payload,categoryId)
                    .then(response => {
                        new Promise((resolve, reject) => {
                            vx.$emit("event", resolve);
                            resolve(response);
                        }).then((response) => {
                            let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: "Data Kategori berhasil diubah.",
                            swalType: "success",
                            onConfirmButton: function() {
                                vx.refreshData(true);
                                blockUI.unblockModal();
                            }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: vx.getServerError(
                            error,
                            "Data Kategori gagal diubah."
                        ),
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                });
        },
        dataPhotoToFile(photo) {
            let bstr = atob(photo.image),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            let newFile = new File([u8arr], photo.originName, {
                type: photo.imageContentType
            });
            return newFile;
        }

    }, //methods
    mounted() {
        $("#" + this.modalName).modal();
        this.dropZone();
        this.updateData();
    }
};
</script>
